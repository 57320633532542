import { useState } from "react";
import { Button } from "./ui/button";
import { supabase } from "../supabaseClient";

interface ErrorPopupProps {
  error: { message: string; code: string };
  onClose: () => void;
}

const ErrorPopup: React.FC<ErrorPopupProps> = ({ error, onClose }) => {
  const [isReporting, setIsReporting] = useState(false);
  const [reportSuccess, setReportSuccess] = useState<boolean | null>(null);

  const handleReport = async () => {
    setIsReporting(true);

    // Obtener usuario autenticado si hay sesión
    const { data: userData } = await supabase.auth.getUser();
    const userId = userData?.user?.id || null;

    // Guardar error en Supabase
    const { error: insertError } = await supabase
      .from("error_reports")
      .insert([{ 
        message: error.message, 
        error_code: error.code, 
        timestamp: new Date().toISOString(), 
        user_id: userId 
      }]);

    setIsReporting(false);

    setReportSuccess(!insertError);
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
      <div className="bg-white p-6 rounded-lg shadow-lg w-[400px] text-center">
        <h2 className="text-lg font-bold text-red-600">¡Ha ocurrido un error!</h2>
        <p className="text-sm text-gray-500">Código de error: {error.code}</p>

        <div className="mt-4 flex justify-between">
          <Button variant="outline" onClick={onClose}>Cerrar</Button>
          <Button onClick={handleReport} disabled={isReporting}>
            {isReporting ? "Reportando..." : "Reportar Error"}
          </Button>
        </div>

        {reportSuccess === true && (
          <p className="mt-2 text-green-600">Error reportado con éxito.</p>
        )}
        {reportSuccess === false && (
          <p className="mt-2 text-red-600">No se pudo reportar el error.</p>
        )}
      </div>
    </div>
  );
};

export default ErrorPopup;
