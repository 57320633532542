/** @format */

import React, { useState, useEffect } from "react";
import { Avatar, AvatarFallback, AvatarImage } from "./ui/avatar";
import { Edit, Check } from "lucide-react";
import { useTranslation } from "react-i18next";
import { supabase } from "../supabaseClient";
import { Button } from "./ui/button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "../components/ui/dropdown-menu";
import ErrorPopup from "../components/ErrorPopup";


interface PersonalInformationProps {
  userInfo: {
    id: string; // Se asume que este campo existe en userInfo
    name: string;
    nombre: string;
    companies: {id: number, name: string}[]
  };
  user: {
    email?: string;
    id: string;
  };
  language: string;
  company: any;
  handleLanguageChange: (language: string) => void;
  handleCompanyChange: (company_id: number) => void;
  setUserInfo: (info: any) => void;
}

const PersonalInformation: React.FC<PersonalInformationProps> = ({
  userInfo,
  user,
  company,
  handleLanguageChange,
  handleCompanyChange,
  setUserInfo,
}) => {
  const { t } = useTranslation();
  const [avatarUrl, setAvatarUrl] = useState<string>("");
  const [error, setError] = useState<{ message: string; code: string } | null>(null);
  const [isEditing, setIsEditing] = useState(false);
  const [editedName, setEditedName] = useState(userInfo.name);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchAvatar = async () => {
      try {
        const { data: listData, error: listError } = await supabase.storage
          .from("avatars")
          .list(userInfo.id, { limit: 1 });

        if (listError) {
          console.error("Error fetching avatar list:", listError);
          return;
        }

        if (listData && listData.length > 0) {
          const filePath = `${userInfo.id}/${listData[0].name}`;
          const { data } = supabase.storage
            .from("avatars")
            .getPublicUrl(filePath);

          if (!data) {
            console.error("Error fetching avatar URL:");
          } else if (data) {
            setAvatarUrl(`${data.publicUrl}?t=${new Date().getTime()}`); // Prevent caching
          }
        }
      } catch (err: any) {
        console.error("Error fetching avatar:", err);
        setError({ message: err.message, code: "OMN-005" });
      }
    };

    fetchAvatar();
  }, [userInfo.id]);

  const handleAvatarUpload = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const file = event.target.files?.[0];
    if (!file) return;

    try {
      const folderName = userInfo.id;

      // Borrar los avatares actuales
      const { data: listData, error: listError } = await supabase.storage
        .from("avatars")
        .list(folderName);

      if (listError) {
        console.error("Error listing avatar files:", listError);
      } else if (listData && listData.length > 0) {
        for (const file of listData) {
          await supabase.storage.from("avatars").remove([`${folderName}/${file.name}`]);
        }
      }

      const fileName = `${Date.now()}_${file.name}`;
      const filePath = `${folderName}/${fileName}`;

      const { error: uploadError } = await supabase.storage
        .from("avatars")
        .upload(filePath, file, { upsert: true });

      if (uploadError) {
        throw uploadError;
      }

      const { data: publicUrlData } = supabase.storage
        .from("avatars")
        .getPublicUrl(filePath);

      if (publicUrlData?.publicUrl) {
        setAvatarUrl(`${publicUrlData.publicUrl}?t=${new Date().getTime()}`); // Prevent caching
      }
    } catch (err: any) {
      console.error("Error uploading avatar:", err);
      setError({ message: err.message, code: "OMN-006" });
    }
  };

  const handleSaveName = async () => {
    if (editedName.trim() === "" || editedName === userInfo.name) {
      setIsEditing(false);
      return;
    }

    setLoading(true);
    try {
      const { error } = await supabase
        .from("assistant")
        .update({ name: editedName })
        .eq("id", userInfo.id);

      if (error) {
        console.error("Error updating name:", error);
      } else {
        setUserInfo({ ...userInfo, name: editedName });
        setIsEditing(false);
      }
    } catch (err: any) {
      console.error("Error updating name:", err);
      setError({ message: err.message, code: "OMN-007" });
    }
    setLoading(false);
  };

  return (
    <div className="flex items-center md:items-start gap-4 md:gap-6 p-4">
      {error && <ErrorPopup error={error} onClose={() => setError(null)} />}
      <div className="relative flex flex-col items-center gap-4">
        <Avatar className="w-20 h-20 md:w-32 md:h-32">
          <AvatarImage
            src={avatarUrl || "/placeholder.svg?height=128&width=128"}
            alt={userInfo.nombre}
          />
          <AvatarFallback>{userInfo.nombre}</AvatarFallback>
        </Avatar>
        <label
          htmlFor="avatar-upload"
          className="absolute -top-4 -right-4  p-2 rounded-full cursor-pointer"
        >
          <Edit className="w-5 h-5 text-gray-600" />
          <input
            id="avatar-upload"
            type="file"
            accept="image/*"
            className="hidden"
            onChange={handleAvatarUpload}
          />
        </label>
        <div className="flex flex-col items-center">
          <label className="block text-sm text-center mb-2">
            {t("language")}
          </label>
          <div className="flex gap-4">
            <button
              onClick={() => handleLanguageChange("en")}
              className="flex items-center justify-center w-10 h-10 rounded-full bg-gray-200 hover:bg-gray-300 focus:outline-none"
              title="English"
            >
              🇬🇧
            </button>
            <button
              onClick={() => handleLanguageChange("es")}
              className="flex items-center justify-center w-10 h-10 rounded-full bg-gray-200 hover:bg-gray-300 focus:outline-none"
              title="Spanish"
            >
              🇪🇸
            </button>
          </div>
        </div>
      </div>

      <div className="space-y-2 md:space-y-4">
        <div className="flex items-center gap-2">
          {isEditing ? (
            <input
              type="text"
              value={editedName}
              onChange={(e) => setEditedName(e.target.value)}
              onBlur={handleSaveName}
              onKeyDown={(e) => {
                if (e.key === "Enter") handleSaveName();
                if (e.key === "Escape") setIsEditing(false);
              }}
              className="border-b-2 border-primary focus:outline-none focus:border-primary-dark text-xl md:text-2xl font-semibold bg-white text-black px-2 py-1"
              autoFocus
            />
          ) : (
            <h2 className="text-xl md:text-2xl font-semibold">{userInfo.name}</h2>
          )}
          <button onClick={() => setIsEditing(true)} className="ml-2">
            {loading ? (
              <Check className="w-4 h-4 text-green-500" />
            ) : (
              <Edit className="w-4 h-4 text-gray-500 hover:text-gray-700" />
            )}
          </button>
        </div>

        <div className="flex flex-col gap-1 md:gap-2">
          <div className="flex items-center gap-2">
            <span className="text-sm md:text-base">{user.email}</span>
          </div>
        </div>
        <div className="flex items-center space-x-4">
            <DropdownMenu>
              <DropdownMenuTrigger asChild>
                <Button variant="outline">
                  {company.name}
                </Button>
              </DropdownMenuTrigger>
              <DropdownMenuContent align="end">
                {userInfo.companies.map(company => {
                  return (
                    <DropdownMenuItem key={company.id} onClick={() => {handleCompanyChange(company.id)}}>
                      {company.name}
                    </DropdownMenuItem>
                  );
                })}
              </DropdownMenuContent>
            </DropdownMenu>
          </div>
      </div>
    </div>
  );
};

export default PersonalInformation;
