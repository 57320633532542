/** @format */

import React, { createContext, useState, useEffect, useContext } from "react";
import { Session, User } from "@supabase/supabase-js";
import { supabase } from "../supabaseClient";
import { useTranslation } from "react-i18next";
import { specialties } from "../lib/specialties";

interface TransferNotification {
  transferId: number;
  is_urgent: boolean;
  question: string;
}

interface MessageNotification {
  userId: string;
  sender: string;
  content: string;
}

interface SessionContextType {
  session: Session | null;
  user: User | null;
  userInfo: any | null;
  loading: boolean;
  myUsers: any[];
  notifications: {
    messages: { [userId: string]: boolean };
    transfers: { [userId: string]: TransferNotification[] };
  };
  addNotification: (userId: string) => void;
  addTransferNotification: (
    userId: string,
    transfer: TransferNotification
  ) => void;
  clearNotification: (userId: string) => void;
  clearTransferNotification: (userId: string, transferId: number) => void;
  activePhone: any;
  setActivePhone: any;
  setMyUsers: any;
  isTransferNotification: any;
  setIsTransferNotification: any;
  updateNotificationFlag: (userId: string) => void;
  viewingNotifications: any;
  setViewingNotifications: any;
  language: string;
  setLanguage: any;
  recentUsers: any;
  setRecentUsers: any;
  addRecentUser: any;
  clearUnusedRecentUsers: () => void;
  refreshTokenIfNeeded: () => void;
  returnControlToAi: boolean;
  setReturnControlToAi: any;
  setUserInfo: any;
  company: any;
  updateCompany: any;
}

const updateSpecialtyLanguage = (userInfo: any, language: string) => {
  if (userInfo?.specialty_id && specialties[userInfo.specialty_id]) {
    const specialty = specialties[userInfo.specialty_id][language];
    return { ...userInfo, specialty };
  }
  return userInfo;
};

const SessionContext = createContext<SessionContextType | undefined>(undefined);

export const SessionProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const { t } = useTranslation();
  const [returnControlToAi, setReturnControlToAi] = useState<boolean>(false);
  const [session, setSession] = useState<Session | null>(null);
  const [user, setUser] = useState<User | null>(null);
  const [userInfo, setUserInfo] = useState<any | null>(() => {
    const storedUserInfo = localStorage.getItem("userInfo");
    return storedUserInfo ? JSON.parse(storedUserInfo) : null;
  });
  const [language, setLanguage] = useState<string>("es");
  const [company, setCompany] = useState<any>(null);
  const [loading, setLoading] = useState(true);
  const [myUsers, setMyUsers] = useState<any[]>([]);
  const [notifications, setNotifications] = useState<{
    messages: { [userId: string]: boolean };
    transfers: { [userId: string]: TransferNotification[] };
  }>({
    messages: {},
    transfers: {},
  });
  const [activePhone, setActivePhone] = useState<string | null>(null);
  const [isTransferNotification, setIsTransferNotification] = useState(false);
  const [viewingNotifications, setViewingNotifications] = useState(false);
  const [recentUsers, setRecentUsers] = useState<any[]>([]);

  const addRecentUser = (user: any) => {
    setRecentUsers([user]);
  };

  const clearUnusedRecentUsers = () => {
    setRecentUsers([]);
  };

  useEffect(() => {
    if (userInfo) {
      const updatedUserInfo = updateSpecialtyLanguage(userInfo, language);
      setUserInfo(updatedUserInfo);
    }
  }, [language]);

  useEffect(() => {
    const initializeSession = async () => {
      const { data } = await supabase.auth.getSession();
      const currentSession = data?.session || null;

      setSession(currentSession);
      setUser(currentSession?.user || null);

      if (currentSession?.user) {
        await Promise.all([
          fetchUserInfo(currentSession),
          fetchUsers(currentSession),
        ]);
      }

      setLoading(false);
    };

    initializeSession();

    const {
      data: { subscription },
    } = supabase.auth.onAuthStateChange(async (_event, newSession) => {
      setSession(newSession);
      setUser(newSession?.user || null);

      if (newSession?.user) {
        await refreshTokenIfNeeded();
        fetchUserInfo(newSession);
        fetchUsers(newSession);
      } else {
        setUserInfo(null);
        setMyUsers([]);
      }
    });

    return () => subscription.unsubscribe();
  }, []);

  useEffect(() => {
    if (!company) return;
    
    // Fetch pending transfer for myUsers
    fetchPendingTransfers();

    // Subscribe to veterinarian_transfer notifications
    const transferChannel = supabase
      .channel("veterinarian_transfer")
      .on(
        "postgres_changes",
        { event: "INSERT", schema: "public", table: "notifications" },
        (payload) => {

          const { id, question, is_urgent, is_read, user_id } = payload.new;

          const user = myUsers.find(u => u.id === user_id);

          if (!user) return;

          if (!is_read && user.company_id === company.id) {
            setNotifications((prev) => ({
              ...prev,
              transfers: {
                ...prev.transfers,
                [user_id]: [
                  ...(prev.transfers[user_id] || []),
                  { transferId: id, is_urgent, question },
                ],
              },
            }));
          }
        }
      )
      .subscribe();

    // Subscribe to mensajes_whatsapp notifications
    const messageChannel = supabase
      .channel("mensajes_whatsapp")
      .on(
        "postgres_changes",
        { event: "INSERT", schema: "public", table: "communication_log" },
        (payload) => {
          const { user_id, sender } = payload.new;

          const user = myUsers.find(u => u.id === user_id);

          if (!user) return;

          if (sender === "user" && user.company_id === company.id) {
            setNotifications((prev) => ({
              ...prev,
              messages: {
                ...prev.messages,
                [user_id]: true,
              },
            }));
          }
        }
      )
      .subscribe();

    return () => {
      transferChannel.unsubscribe();
      messageChannel.unsubscribe();
    };
  }, [company, myUsers]);

  const fetchPendingTransfers = async () => {
    try {
      const { data, error } = await supabase
        .from("notifications")
        .select("*, user!inner(company_id)")
        .eq("is_read", false)
        .eq("user.company_id", company.id);

      if (error) {
        console.error("Error fetching pending transfers:", error);
        return;
      }

      if (data) {
        const groupedTransfers: { [userId: string]: TransferNotification[] } =
          {};

        data.forEach(({ id, question, is_urgent, is_read, user_id }) => {
          if (!groupedTransfers[user_id]) groupedTransfers[user_id] = [];
          groupedTransfers[user_id].push({ transferId: id, is_urgent, question });
        });

        setNotifications((prev) => ({
          ...prev,
          transfers: groupedTransfers,
        }));
      }
    } catch (error) {
      console.error("Error fetching pending transfers:", error);
    }
  };

  const refreshTokenIfNeeded = async () => {
    if (!session) return;

    const tokenExpiry = session.expires_at ? session.expires_at * 1000 : null;
    const now = Date.now();

    if (tokenExpiry && tokenExpiry - now < 60000) {
      try {
        const { data, error } = await supabase.auth.refreshSession();

        if (error) {
          console.error("Error refreshing token:", error.message);
          setSession(null);
          setUser(null);
          setUserInfo(null);
          setMyUsers([]);
        } else {
          setSession(data.session);
          setUser(data.session?.user || null);
        }
      } catch (error) {
        console.error("Error during token refresh:", error);
      }
    }
  };

  const addNotification = (userId: string) => {
    setNotifications((prev) => ({
      ...prev,
      messages: { ...prev.messages, [userId]: true },
    }));
  };

  const addTransferNotification = (
    userId: string,
    transfer: TransferNotification
  ) => {
    setNotifications((prev) => ({
      ...prev,
      transfers: {
        ...prev.transfers,
        [userId]: [...(prev.transfers[userId] || []), transfer],
      },
    }));
  };

  const updateNotificationFlag = async (userId: string) => {
    try {
      await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/api/notifications/${userId}`,
        {
          method: "PATCH",
          headers: {
            Authorization: `${session!.token_type} ${session!.access_token}`,
            "Content-Type": "application/json",
          },
        }
      );
    } catch (error) {
      console.error("Error updating notification flag:", error);
    }
  };

  const clearNotification = async (userId: string) => {
    try {
      setNotifications((prev) => ({
        ...prev,
        messages: { ...prev.messages, [userId]: false },
        transfers: { ...prev.transfers, [userId]: [] },
      }));
    } catch (error) {
      console.error("Error updating notification as seen:", error);
    }
  };

  const clearTransferNotification = async (
    userId: string,
    transferId: number
  ) => {
    try {
      setNotifications((prev) => ({
        ...prev,
        transfers: {
          ...prev.transfers,
          [userId]: (prev.transfers[userId] || []).filter(
            (transfer) => transfer.transferId !== transferId
          ),
        },
      }));
    } catch (error) {
      console.error("Error clearing transfer notification:", error);
    }
  };

  const fetchUsers = async (session: Session) => {
    await refreshTokenIfNeeded();

    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/api/callcenter/${session?.user.id}/users`,
        {
          method: "GET",
          headers: {
            Authorization: `${session.token_type} ${session.access_token}`,
          },
        }
      );
      const data = await response.json();
      const usersList = data.map((user: any) => ({
        id: user.id,
        displayName: `${user.name}`,
        new_messages: user.new_messages,
        ai_block: user.ai_block,
        ai_block_by: user.ai_block_by,
        phone: user.phone,
        company_id: user.company_id
      }));
      setMyUsers(usersList);

      setNotifications((prev) => ({
        ...prev,
        messages: usersList.reduce((acc: any, user: any) => {
          if (user.new_messages) {
            acc[user.id] = true;
          }
          return acc;
        }, {}),
        transfers: { ...prev.transfers },
      }));
    } catch (error) {
      console.error("Error fetching users:", error);
    }
  };

  const fetchUserInfo = async (session: Session) => {
    await refreshTokenIfNeeded();

    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/api/callcenter/${session.user.id}`,
        {
          method: "GET",
          headers: {
            Authorization: `${session.token_type} ${session.access_token}`,
          },
        }
      );

      if (!response.ok) {
        console.error("Error fetching call center assistant info:", response.statusText);
        return;
      }

      const data = await response.json();
      if (data.language) {
        setLanguage(data.language);
      }

      if (data.companies) {
        const localCompany = data.companies.find((c: any) => c.id.toString() === localStorage.getItem("company_id"))
        updateCompany(localCompany || data.companies[0]);
      }

      updateFaviconAndTitle();

      localStorage.setItem("userInfo", JSON.stringify(data));
  
      setUserInfo(data);
    } catch (error) {
      console.error("Error fetching call center assistant info:", error);
    }
  };

  const updateFaviconAndTitle = () => {
    const faviconLink: any = document.querySelector('link[rel="icon"]');

    // Listas de URLs
    const isVeterinarian = process.env.REACT_APP_ENV === "veterinarian"

    if (!isVeterinarian) {
      faviconLink.href = "../favicon.svg";
      document.title = t("omnicare");
    } else {
      faviconLink.href = "../faviconVet.svg";
      document.title = t("omnivet");
    }

  };

  const updateCompany = (newCompany: any) => {
    setCompany(newCompany);
    localStorage.setItem("company_id", newCompany.id);
  }

  useEffect(() => {
    const intervalId = setInterval(() => {
      refreshTokenIfNeeded();
    }, 5 * 60 * 1000);

    return () => clearInterval(intervalId);
  }, [session]);

  return (
    <SessionContext.Provider
      value={{
        session,
        user,
        userInfo,
        setUserInfo,
        language,
        setLanguage,
        company,
        updateCompany,
        loading,
        myUsers,
        setMyUsers,
        notifications,
        addNotification,
        addTransferNotification,
        clearNotification,
        clearTransferNotification,
        activePhone,
        setActivePhone,
        isTransferNotification,
        setIsTransferNotification,
        updateNotificationFlag,
        viewingNotifications,
        setViewingNotifications,
        recentUsers,
        setRecentUsers,
        addRecentUser,
        clearUnusedRecentUsers,
        refreshTokenIfNeeded,
        returnControlToAi,
        setReturnControlToAi,
      }}
    >
      {children}
    </SessionContext.Provider>
  );
};

export const useSession = () => {
  const context = useContext(SessionContext);
  if (!context) {
    throw new Error("useSession must be used within a SessionProvider");
  }
  return context;
};
