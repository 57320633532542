// src/components/PrivateRoute.js
import { useState, useEffect } from "react";
import { Navigate } from "react-router-dom";
import { useSession } from "../context/SessionContext";
import { supabase } from "../supabaseClient";

const PrivateRoute = ({ children }) => {
  const { session, loading } = useSession();
  const [mustChangePassword, setMustChangePassword] = useState(false);
  const [checking, setChecking] = useState(true);

  useEffect(() => {
    const checkPasswordStatus = async () => {
      if (!session) {
        setChecking(false);
        return;
      }

      // Consultar si el usuario debe cambiar la contraseña en la tabla `assistant`
      const { data, error } = await supabase
        .from("assistant") 
        .select("must_change_password")
        .eq("id", session.user.id)
        .single();

      if (error) {
        console.error("Error obteniendo el estado de la contraseña:", error.message);
      }

      setMustChangePassword(data?.must_change_password || false);
      setChecking(false);
    };

    checkPasswordStatus();
  }, [session]);

  if (loading || checking) return null; // Espera hasta que termine la verificación

  if (!session) return <Navigate to="/login" />; // Si no está autenticado, redirigir a login

  if (mustChangePassword) return <Navigate to="/set-password" />; // Bloquea el acceso a la app

  return children; // Si todo está bien, renderiza la página solicitada
};

export default PrivateRoute;
