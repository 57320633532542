/** @format */

import React, { useEffect, useState } from "react";
import { Sidebar, SidebarHeader, SidebarContent } from "./ui/sidebar";
import { ScrollArea } from "./ui/scroll-area";
import { useSession } from "../context/SessionContext";
import { useTranslation } from "react-i18next";
import { format } from "date-fns";
import { Button } from "./ui/button";
import {
  Dialog,
  DialogTrigger,
  DialogContent,
  DialogHeader,
  DialogFooter,
  DialogTitle,
  DialogDescription,
  DialogClose,
} from "./ui/dialog";
import { motion, AnimatePresence } from "framer-motion";

interface ChatSidebarProps {
  onSelectUser: (phone: string) => void;
}

const ChatSidebar = ({ onSelectUser }: ChatSidebarProps) => {
  const { t } = useTranslation();
  const {
    myUsers,
    notifications,
    updateNotificationFlag,
    session,
    setIsTransferNotification,
    recentUsers,
    clearUnusedRecentUsers,
    refreshTokenIfNeeded,
    setReturnControlToAi,
  } = useSession();

  const [urgentTransfers, setUrgentTransfers] = useState<any[]>([]);
  const [regularNotifications, setRegularNotifications] = useState<any[]>([]);
  const [activeNotification, setActiveNotification] = useState<{
    userId: number;
    transferId?: number;
  } | null>(null);
  const [modalData, setModalData] = useState<{
    userId: number;
    transferId: number;
  } | null>(null);

  useEffect(() => {
    const allTransfers = Object.entries(notifications.transfers || {}).flatMap(
      ([userId, transfers]) =>
        transfers.map((transfer: any) => ({
          userId,
          transferId: transfer.transferId,
          type: "transfer",
          content: transfer.question,
          date: transfer.date || new Date().toISOString(),
          urgent: transfer.is_urgent,
          read: transfer.is_read || false,
        }))
    
    );

    const uniqueTransfers = Object.values(
      allTransfers.reduce((acc: any, transfer) => {
        if (
          !acc[transfer.userId] ||
          transfer.urgent ||
          (!transfer.urgent && !acc[transfer.userId].urgent)
        ) {
          acc[transfer.userId] = transfer;
        }
        return acc;
      }, {})
    );

    const transferUserIds = new Set(uniqueTransfers.map((t: any) => t.userId));

    const regularNotifications = Object.entries(notifications.messages || {})
      .filter(([userId]) => !transferUserIds.has(Number(userId)))
      .map(([userId, hasMessage]) => ({
        userId: userId,
        type: "message",
        content: t("new_message_received"),
        date:
          myUsers.find((u) => u.id === userId)?.lastMessageDate ||
          new Date().toISOString(),
        read: !hasMessage,
      }));

    const urgentTransfers = uniqueTransfers.filter((t: any) => t.urgent);

    const nonUrgentTransfers = uniqueTransfers.filter((t: any) => !t.urgent);

    const mergedRegularNotifications = [
      ...regularNotifications,
      ...nonUrgentTransfers,
    ].sort(
      (a: any, b: any) =>
        new Date(b.date).getTime() - new Date(a.date).getTime()
    );

    setUrgentTransfers(urgentTransfers);
    setRegularNotifications(mergedRegularNotifications);
  }, [notifications, myUsers, t]);

  useEffect(() => {
    if (recentUsers.length > 0) {
      const activeUser = recentUsers[0];
      onSelectUser(activeUser.phone);
    }
  }, [recentUsers, onSelectUser]);

  const markTransferAsSeen = async (userId: number, transferId: number, isUrgent: boolean) => {
    try {
      refreshTokenIfNeeded();

      if (!transferId) {
        await updateNotificationFlag(userId.toString());
        setRegularNotifications((prev) =>
          prev.filter((notification) => notification.transferId !== transferId)
        );
        return;
      }

      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/api/communications/markTransferAsSeen/${transferId}/${userId}`,
        {
          method: "PATCH",
          headers: {
            Authorization: `${session!.token_type} ${session!.access_token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ is_read: true }),
        }
      );
      setReturnControlToAi(true);
      if (!response.ok) {
        throw new Error("Failed to mark transfer as seen");
      }

      if (isUrgent) {
        setUrgentTransfers((prev) =>
          prev.filter((notification) => notification.transferId !== transferId)
        );
      } else {
        setRegularNotifications((prev) =>
          prev.filter((notification) => notification.transferId !== transferId)
        );
      }

    } catch (error) {
      console.error("Error marking transfer as seen:", error);
    }
  };

  const handleNotificationClick = async (
    userId: number,
    phone: string,
    type: string,
    isUrgent: boolean,
    transferId?: number
  ) => {
    clearUnusedRecentUsers();

    setActiveNotification({ userId, transferId });

    if (isUrgent) {
      setIsTransferNotification(true);
    }

    if (type === "transfer") {
      setUrgentTransfers((prev) =>
        prev.map((notification) =>
          notification.userId === userId &&
          (transferId ? notification.transferId === transferId : true)
            ? { ...notification, read: true }
            : notification
        )
      );
    } else {
      setRegularNotifications((prev) =>
        prev.map((notification) =>
          notification.userId === userId
            ? { ...notification, read: true }
            : notification
        )
      );
    }

    const user = myUsers.find((u) => u.id === userId);
    const userPhone = user?.phone || phone;

    if (userPhone) {
      onSelectUser(userPhone);
    }
  };

  const hasUrgentTransfers = urgentTransfers.length > 0;
  const hasRegularNotifications = regularNotifications.length > 0;
  const hasRecentUser = recentUsers.length > 0;

  return (
    <Sidebar className="w-full md:w-64 h-full bg-gray-100">
      <SidebarHeader className="p-4 bg-[#00AAC6] text-white">
        <h2 className="text-2xl font-bold">{t("notifications")}</h2>
      </SidebarHeader>
      <SidebarContent>
        <ScrollArea className="h-full overflow-y-auto">
          <div className="p-4">
            {hasRecentUser && (
              <div className="mb-6">
                <h3 className="text-lg font-semibold text-gray-800">
                  {t("user_chat")}
                </h3>
                <ul className="mt-2 space-y-2">
                  <AnimatePresence>
                    {recentUsers.slice(-1).map((user: any) => (
                      <motion.li
                        key={user.id}
                        initial={{ opacity: 0, y: -10 }}
                        animate={{ opacity: 1, y: 0 }}
                        exit={{ opacity: 0, y: -10 }}
                        transition={{ duration: 0.2 }}
                        className="p-2 border rounded-md bg-white shadow-sm"
                      >
                        <div className="flex items-center">
                          <div className="flex-grow">
                            <h4 className="font-bold text-gray-900">
                              {user.user.name}
                            </h4>
                          </div>
                        </div>
                      </motion.li>
                    ))}
                  </AnimatePresence>
                </ul>
              </div>
            )}
            {hasUrgentTransfers && (
              <div className="mb-6">
                <h3 className="text-lg font-semibold text-red-500">
                  {t("urgent_messages")}
                </h3>
                <ul className="mt-2 space-y-2">
                  <AnimatePresence>
                    {urgentTransfers.map((notification) => {
                      const user = myUsers.find(
                        (u) => u.id === notification.userId
                      );
                      const isActive =
                        activeNotification?.userId === notification.userId &&
                        activeNotification?.transferId ===
                          notification.transferId;
                      return (
                        <motion.li
                          key={`${notification.type}-${notification.userId}-${notification.transferId}`}
                          initial={{ opacity: 1, y: 0 }}
                          exit={{ opacity: 0, y: -20 }}
                          transition={{ duration: 0.3 }}
                          className={`p-2 border-b last:border-b-0 cursor-pointer rounded-md ${
                            isActive
                              ? "bg-blue-100"
                              : notification.read
                              ? "bg-gray-100"
                              : "bg-white"
                          } hover:bg-gray-200`}
                          onClick={() =>
                            handleNotificationClick(
                              notification.userId,
                              user?.phone || "",
                              notification.type,
                              true,
                              notification.transferId
                            )
                          }
                        >
                          <div className="flex justify-between items-center">
                            <span className="font-bold">
                              {user?.displayName ||
                                t("default_user", {
                                  userId: notification.userId,
                                })}
                            </span>
                            <span className="text-xs text-gray-500">
                              {format(
                                new Date(notification.date),
                                "dd/MM/yyyy HH:mm"
                              )}
                            </span>
                          </div>
                          <p className="text-sm text-gray-700">
                            {notification.content}
                          </p>
                          <div className="flex justify-end space-x-2 mt-2">
                            <Dialog
                              open={!!modalData}
                              onOpenChange={(open) =>
                                !open && setModalData(null)
                              }
                            >
                              <DialogTrigger asChild>
                                <Button
                                  variant="outline"
                                  size="sm"
                                  onClick={() =>
                                    setModalData({
                                      userId: notification.userId,
                                      transferId: notification.transferId,
                                    })
                                  }
                                >
                                  {t("mark_resolved")}
                                </Button>
                              </DialogTrigger>
                              <DialogContent>
                                <DialogHeader>
                                  <DialogTitle>
                                    {t("confirm_resolution")}
                                  </DialogTitle>
                                  <DialogDescription>
                                    {t("are_you_sure_mark_resolved")}
                                  </DialogDescription>
                                </DialogHeader>
                                <DialogFooter>
                                  <DialogClose asChild>
                                    <Button variant="outline">
                                      {t("cancel")}
                                    </Button>
                                  </DialogClose>
                                  <Button
                                    variant="default"
                                    onClick={() => {
                                      markTransferAsSeen(
                                        modalData!.userId,
                                        modalData!.transferId,
                                        true
                                      );
                                      setModalData(null);
                                    }}
                                  >
                                    {t("confirm")}
                                  </Button>
                                </DialogFooter>
                              </DialogContent>
                            </Dialog>
                          </div>
                        </motion.li>
                      );
                    })}
                  </AnimatePresence>
                </ul>
              </div>
            )}
            {hasRegularNotifications && (
              <div>
                <h3 className="text-lg font-semibold text-gray-800">
                  {t("new_messages")}
                </h3>
                <ul className="mt-2 space-y-2">
                  <AnimatePresence>
                    {regularNotifications.map((notification) => {
                      const user = myUsers.find(
                        (u) => u.id === notification.userId
                      );
                      const isActive =
                        activeNotification?.userId === notification.userId;
                      return (
                        <motion.li
                          key={`${notification.type}-${notification.userId}`}
                          initial={{ opacity: 1, y: 0 }}
                          exit={{ opacity: 0, y: -20 }}
                          transition={{ duration: 0.3 }}
                          className={`p-2 border-b last:border-b-0 cursor-pointer rounded-md ${
                            isActive
                              ? "bg-blue-100"
                              : notification.read
                              ? "bg-gray-100"
                              : "bg-white"
                          } hover:bg-gray-200`}
                          onClick={() =>
                            handleNotificationClick(
                              notification.userId,
                              user?.phone || "",
                              notification.type,
                              false
                            )
                          }
                        >
                          <div className="flex justify-between items-center">
                            <span className="font-bold">
                              {user?.displayName ||
                                t("default_user", {
                                  userId: notification.userId,
                                })}
                            </span>
                            <span className="text-xs text-gray-500">
                              {format(
                                new Date(notification.date),
                                "dd/MM/yyyy HH:mm"
                              )}
                            </span>
                          </div>
                          <p className="text-sm text-gray-700">
                            {notification.content}
                          </p>
                          <div className="flex justify-end space-x-2 mt-2">
                            <Button
                              variant="outline"
                              size="sm"
                              onClick={() =>
                                markTransferAsSeen(
                                  notification.userId,
                                  notification.transferId,
                                  false
                                )
                              }
                              >
                              {t("mark_resolved")}
                            </Button>
                          </div>
                        </motion.li>
                      );
                    })}
                  </AnimatePresence>
                </ul>
              </div>
            )}
            {!hasUrgentTransfers &&
              !hasRegularNotifications &&
              !hasRecentUser && (
                <p className="text-sm text-gray-500">
                  {t("no_notifications_available")}
                </p>
              )}
          </div>
        </ScrollArea>
      </SidebarContent>
    </Sidebar>
  );
};

export default ChatSidebar;
