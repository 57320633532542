import { Component, ReactNode } from "react";
import ErrorPopup from "./ErrorPopup";

interface ErrorBoundaryProps {
  children: ReactNode;
}

interface ErrorBoundaryState {
  hasError: boolean;
  error: { message: string; code: string } | null;
}

class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false, error: null };
  }

  static getDerivedStateFromError(error: Error) {
    return { hasError: true, error: { message: error.message, code: "OMN-000" } };
  }

  componentDidCatch(error: Error, errorInfo: any) {
    console.error("Error capturado en ErrorBoundary:", error, errorInfo);
  }

  closePopup = () => {
    this.setState({ hasError: false, error: null });
  };

  render() {
    if (this.state.hasError && this.state.error) {
      return <ErrorPopup error={this.state.error} onClose={this.closePopup} />;
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
