/** @format */

import { useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import { supabase } from "../supabaseClient";
import { Button } from "../components/ui/button";
import { Input } from "../components/ui/input";
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from "../components/ui/card";
import { PawPrint } from "lucide-react";
import { useSession } from "../context/SessionContext";

const Login = () => {
  const isVeterinarian = process.env.REACT_APP_ENV === "veterinarian"
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();
  const { session } = useSession();

  if (session) {
    navigate("/");
    return null;
  }

  const handleLogin = async (e: React.FormEvent) => {
    e.preventDefault();
  
    const { data, error } = await supabase.auth.signInWithPassword({
      email,
      password,
    });
  
    if (error) {
      alert(error.message);
      return;
    }
  
    const { data: userData, error: userError } = await supabase
      .from("assistant") 
      .select("must_change_password")
      .eq("email", email)
      .single();

    if (userError) {
      console.error("Error obteniendo datos del usuario:", userError);
    } else if (userData?.must_change_password) {
      console.log("Reset the password")
      navigate("/reset-password");
      return;
    }
  
    navigate("/");
  };
  


  return (
    <div className="flex items-center justify-center min-h-screen bg-background">
      <Card className="w-[350px] bg-[#f6f7f7]">
        <CardHeader className="space-y-1">
          <CardTitle className="text-2xl font-bold flex items-center justify-center">
            {isVeterinarian ? (
              <>
                <PawPrint className="mr-2 h-6 w-6" />
                OmniVet Login
              </>
            ) : (
              <>
                <div className="w-full flex h-18 rounded-full">
                  <img
                    src="/omniloy.svg"
                    alt="Profile"
                    className="w-44 h-auto object-cover mx-auto"
                  />
                </div>
              </>
            )}
          </CardTitle>
        </CardHeader>
        <CardContent>
          <form onSubmit={handleLogin} className="space-y-4">
            <div className="space-y-2">
              <Input
                type="email"
                placeholder="Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </div>
            <div className="space-y-2">
              <Input
                type="password"
                placeholder="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
            </div>
            <Button type="submit" className="w-full">
              Login
            </Button>
          </form>
          <div className="mt-4 text-center text-sm">
            <Link to="/forgot-password" className="text-primary hover:underline">
              ¿Olvidaste tu contraseña?
            </Link>
          </div>
        </CardContent>
      </Card>
    </div>
  );
};

export default Login;
