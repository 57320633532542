import { useState } from 'react';
import { Input } from "./ui/input";
import { Button } from "./ui/button";
import { Send } from "lucide-react";
import { useTranslation } from "react-i18next";

interface MessageInputProps {
  onSendMessage: (message: string) => void;
}

const MessageInput = ({ onSendMessage }: MessageInputProps) => {
  const { t } = useTranslation();
  const [message, setMessage] = useState('');

  const handleSend = () => {
    if (message.trim()) {
      onSendMessage(message);
      setMessage('');
    }
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      handleSend();
    }
  };

  return (
    <div className="flex items-center gap-2">
      <Input
        type="text"
        value={message}
        onChange={(e) => setMessage(e.target.value)}
        onKeyDown={handleKeyDown}
        placeholder={t('message_input_placeholder')}
        className="flex-1 bg-white border-blue-300 focus:border-blue-500 focus:ring-blue-500"
      />
      <Button onClick={handleSend} size="icon" className="bg-blue-600 hover:bg-blue-700">
        <Send className="h-4 w-4" />
      </Button>
    </div>
  );
};

export default MessageInput;
