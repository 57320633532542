/** @format */

import { HashRouter as Router, Route, Routes, useLocation } from "react-router-dom";
import { ThemeProvider } from "./components/theme-provider";
import { SessionProvider } from "./context/SessionContext";
import Navbar from "./components/Navbar";
import ChatPage from "./pages/ChatPage";
import Profile from "./pages/Profile";
import Login from "./pages/Login";
import Signup from "./pages/Signup";
import ResetPassword from "./pages/ResetPassword"; 
import ForgotPassword from "./pages/ForgotPassword";
import PrivateRoute from "./components/PrivateRoute";
import SetPassword from "./pages/SetPassword";
import { Toaster } from "react-hot-toast";
import { useEffect, useState } from "react";
import { t } from "i18next";
import ErrorBoundary from "./components/ErrorBoundary"; 

function AppContent() { 
  const location = useLocation(); 
  const hideNavbarRoutes = ["/set-password", "/reset-password", "/forgot-password"];
  const showNavbar = !hideNavbarRoutes.includes(location.pathname);

  const [selectedUserPhone, setSelectedUserPhone] = useState<string | null>(null);

  const handleUserSelect = (phone: string) => {
    setSelectedUserPhone(phone);
  };

  const updateFaviconAndTitle = () => {
    const faviconLink: any = document.querySelector('link[rel="icon"]');

    const isVeterinarian = process.env.REACT_APP_ENV === "veterinarian";

    if (!isVeterinarian) {
      faviconLink.href = "/favicon.svg";
      document.title = t("omnicare");
    } else {
      faviconLink.href = "/faviconVet.svg";
      document.title = t("omnivet");
    }
  };

  useEffect(() => {
    updateFaviconAndTitle();
  }, []);

  return (
    <SessionProvider>
      <div className="min-h-screen bg-background font-sans antialiased">
        {showNavbar && <Navbar onUserSelect={handleUserSelect} />}
        <main className="flex-1 overflow-y-auto">
          <ErrorBoundary> 
            <Routes>
              <Route path="/login" element={<Login />} />
              <Route path="/signup" element={<Signup />} />
              <Route path="/reset-password" element={<ResetPassword />} /> 
              <Route path="/forgot-password" element={<ForgotPassword />} /> 
              <Route path="/set-password" element={<SetPassword />} />
              <Route
                path="/"
                element={
                  <PrivateRoute>
                    <ChatPage selectedUserPhone={selectedUserPhone} />
                  </PrivateRoute>
                }
              />
              <Route
                path="/profile"
                element={
                  <PrivateRoute>
                    <Profile />
                  </PrivateRoute>
                }
              />
            </Routes>
          </ErrorBoundary>
        </main>
      </div>
    </SessionProvider>
  );
}

function App() {
  return (
    <ThemeProvider defaultTheme="dark" storageKey="vite-ui-theme">
      <Toaster
        position="top-center"
        reverseOrder={false}
        gutter={8}
        containerClassName=""
        containerStyle={{}}
        toastOptions={{
          className: "",
          duration: 5000,
          style: {
            background: "#363636",
            color: "#fff",
          },
          success: {
            duration: 3000,
          },
        }}
      />
      <Router> 
        <AppContent /> 
      </Router>
    </ThemeProvider>
  );
}

export default App;
