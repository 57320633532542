import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { supabase } from "../supabaseClient";
import { Button } from "../components/ui/button";
import { Input } from "../components/ui/input";
import { Card, CardContent, CardHeader, CardTitle } from "../components/ui/card";

const SetPassword = () => {
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const authenticateUser = async () => {
      const hashParams = new URLSearchParams(window.location.hash.substring(1));
      const accessToken = hashParams.get("access_token") || "";
      const refreshToken = hashParams.get("refresh_token") || "";

      if (!accessToken || !refreshToken) {
        setLoading(false);
        return;
      }

      const { error } = await supabase.auth.setSession({
        access_token: accessToken,
        refresh_token: refreshToken,
      });

      if (error) {
        setMessage("Error al autenticar. Intenta de nuevo.");
        setLoading(false);
        return;
      }

      setLoading(false);
    };

    authenticateUser();
  }, []);

  const handleSetPassword = async (e: React.FormEvent) => {
    e.preventDefault();
    setMessage("");

    if (password !== confirmPassword) {
      setMessage("Las contraseñas no coinciden.");
      return;
    }

    if (!/^(?=.*[A-Z])(?=.*\d).{8,}$/.test(password)) {
      setMessage("La contraseña debe tener al menos 8 caracteres, una mayúscula y un número.");
      return;
    }

    const { data: userData, error: userError } = await supabase.auth.getUser();
    if (userError || !userData?.user) {
      setMessage("Error: No se pudo verificar tu usuario. Intenta de nuevo.");
      return;
    }

    const { error } = await supabase.auth.updateUser({ password });

    if (error) {
      setMessage("Error al establecer la contraseña.");
      return;
    }

    await supabase
      .from("assistant")
      .update({ must_change_password: false })
      .eq("id", userData.user.id);

    setMessage("Contraseña creada con éxito. Redirigiendo...");

    await supabase.auth.signOut();

    setTimeout(() => navigate("/login"), 2000);
  };

  if (loading) return <p className="text-center mt-10">Cargando...</p>;

  return (
    <div className="flex items-center justify-center min-h-screen bg-background">
      <Card className="w-[350px] bg-[#f6f7f7]">
        <CardHeader>
          <CardTitle className="text-2xl font-bold text-center">
            <div className="w-full flex h-18 rounded-full mb-4">
              <img
                src="/omniloy.svg" 
                alt="Logo"
                className="w-44 h-auto object-cover mx-auto"
              />
            </div>
            Crear tu Contraseña
          </CardTitle>
        </CardHeader>
        <CardContent>
          <form onSubmit={handleSetPassword} className="space-y-4">
            <Input
              type="password"
              placeholder="Nueva contraseña"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
            <Input
              type="password"
              placeholder="Confirmar nueva contraseña"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              required
            />
            <Button type="submit" className="w-full">
              Establecer Contraseña
            </Button>
          </form>
          {message && <p className="mt-4 text-center text-sm text-green-600">{message}</p>}
        </CardContent>
      </Card>
    </div>
  );
};

export default SetPassword;
