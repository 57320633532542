/** @format */

import React, { useEffect, useState } from "react";
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from "../components/ui/card";
import { useSession } from "../context/SessionContext";
import { useTranslation } from "react-i18next";
import PersonalInformation from "../components/PersonalInformation";
import ErrorPopup from "../components/ErrorPopup"; 

export default function Profile() {
  const { t, i18n } = useTranslation();
  const {
    user,
    userInfo,
    loading,
    session,
    language,
    setLanguage,
    company,
    updateCompany,
    setUserInfo,
    refreshTokenIfNeeded,
  } = useSession();
  const [error, setError] = useState<{ message: string; code: string } | null>(null); 

  useEffect(() => {
    if (language) {
      i18n.changeLanguage(language).catch((err) => {
        console.error("Error changing language:", err);
        setError({ message: err.message, code: "OMN-001" });
      });
    }
  }, [language, i18n]);

  const handleLanguageChange = async (language: string) => {
    refreshTokenIfNeeded();
    i18n.changeLanguage(language).catch((err) => {
      console.error("Error changing language:", err);
      setError({ message: err.message, code: "OMN-002" });
    });

    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/api/callcenter/${session!.user.id}/language`,
        {
          method: "PATCH",
          headers: {
            Authorization: `${session!.token_type} ${session!.access_token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ language }),
        }
      );

      if (!response.ok) throw new Error("Error updating language preference");

      const result = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/api/callcenter/${session!.user.id}`,
        {
          method: "GET",
          headers: {
            Authorization: `${session!.token_type} ${session!.access_token}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (!result.ok) throw new Error("Error fetching updated user data");

      const data = await result.json();
      setLanguage(data.language);
      setUserInfo(data);
    } catch (err: any) {
      console.error("Error updating language:", err);
      setError({ message: err.message, code: "OMN-003" });
    }
  };

  const handleCompanyChange = async (company_id: any) => {
    refreshTokenIfNeeded();
    try {
      const selectedCompany = userInfo.companies.find((c: any) => c.id === company_id);
      if (!selectedCompany) throw new Error("Selected company not found");
      updateCompany(selectedCompany);
    } catch (err: any) {
      console.error("Error updating company:", err);
      setError({ message: err.message, code: "OMN-004" });
    }
  };

  if (loading) return <div>{t("loading")}</div>;
  if (!user || !userInfo) return <div>{t("no_user_data")}</div>;

  return (
    <div
      className="container mx-auto p-4 md:p-6"
      style={{ height: "calc(100vh - 100px)", overflowY: "auto" }}
    >
      {error && <ErrorPopup error={error} onClose={() => setError(null)} />} 
      <h1 className="text-2xl md:text-3xl font-bold text-[#00AAC6] mb-4 md:mb-6">
        {t("profile")}
      </h1>
      <div className="grid gap-4 md:gap-6">
        <Card>
          <CardHeader>
            <CardTitle>{t("personal_information")}</CardTitle>
          </CardHeader>
          <CardContent className="flex flex-col md:flex-row gap-4 md:gap-6">
            <div className="flex flex-col gap-4">
              <PersonalInformation
                userInfo={userInfo}
                user={user}
                language={language}
                company={company}
                handleLanguageChange={handleLanguageChange}
                handleCompanyChange={handleCompanyChange}
                setUserInfo={setUserInfo} 
              />
            </div>
          </CardContent>
        </Card>      
      </div>
    </div>
  );
}
